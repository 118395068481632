import React from "react"
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Hidden,
  Collapse,
} from "@material-ui/core"
import WavesIcon from "@material-ui/icons/Waves"
import AccessibilityIcon from "@material-ui/icons/Accessibility"
import SportsHandballIcon from "@material-ui/icons/SportsHandball"
import { makeStyles } from "@material-ui/core/styles"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

const secondParagraph = `Dal 2009 lavora anche come libero professionista presso diversi
centri di fisioterapia all’interno del quale collabora con diverse
società di calcio locali e di serie maggiori (A, B e C), Medici
Fisiatri, Ortopedici e Medici dello sport. Svolge la sua attività
come ecografia clinica, infiltrazioni ecoguidate e come
specialista nell’applicazione della terapia delle Onde d’urto.
Partecipazione a 203 tra corsi, congressi, convegni, etc., di cui
5 semestrali con superamento dell’esame finale, con 7
comunicazioni orali e 21 posters congressuali. Ha partecipato come
docente o relatore a 34 congressi di rilevanza nazionale. Ha
presentato a vari congressi. Ha realizzato 15 pubblicazioni
scientifiche (di rilevanza nazionale ed internazionale in lingua
inglese). In questi anni oltre alla maturata esperienza per la
diagnostica radiologica (Rx, TC, RM e ecografia) in abito
dell’emergenza, neurologica e addominale , ha sviluppato un
interesse specifico per l’apparato muscoloscheletrico.`

const useStyle = makeStyles(theme => ({
  container: {
    borderRadius: "25px",
    zIndex: 3,
    position: "relative",
    width: "80%",
    margin: "-32px auto 32px auto",
    padding: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: theme.spacing(1),
    },
  },
  content: {
    padding: "0 64px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 8px",
    },
  },
  expand: {
    position: "absolute",
    right: "0.9em",
    margin: "auto",
    display: "block",
    zIndex: 10,
  },
  icon: {
    color: "#257771",
  },
}))

const Paragrpah = ({ title, content }) => {
  const classes = useStyle()

  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Paper elevation={3} className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            color="primary"
            gutterBottom
            className={classes.content}
            style={{ marginBottom: "3rem" }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.content}
          >
            Laurea in Medicina e Chirurgia nel novembre 1983 presso l’Università
            degli Studi di Genova superando, nello stesso anno, l’esame di
            stato. Dal 1989, inizia un’importante esperienza formativa presso
            l’Ospedale di Lavagna, sede di Pronto Soccorso di I° livello, dove
            rimarrà fino a dicembre 2016. Consegue nel giugno 1990 la
            specializzazione in Radiodiagnostica presso l’Università di Genova.
            Nel 2010 viene nominato responsabile organizzativo del servizio di
            Radiologia dell’Ospedale di Rapallo, Docente universitario presso la
            scuola di Scienze Infermieristiche (anno 2012/13/14/15/16/17). Nel
            2012 ad oggi pratica la terapia ad Onde D’urto focali (ESWT),
            diventando, membro della{" "}
            <a
              href="https://www.sitod.it"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Società Italiana Onde D’Urto (SITOD)</strong>.
            </a>
            <Hidden smDown>{secondParagraph}</Hidden>
            <Hidden smUp>
              {open ? (
                <ExpandLess className={classes.expand} onClick={handleClick} />
              ) : (
                <ExpandMore className={classes.expand} onClick={handleClick} />
              )}
              <br />
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button>
                    <ListItemText primary={secondParagraph} />
                  </ListItem>
                </List>
              </Collapse>
            </Hidden>
            <div style={{ padding: "16px", fontWeight: "bold" }}>
              In particolare si occupa di :
            </div>
          </Typography>
          <List component="ul" className={classes.content}>
            <ListItem>
              <ListItemIcon className={classes.icon}>
                <SportsHandballIcon />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography variant="h6" color="textSecondary">
                  <strong>
                    Ecografica muscolo-tendinea, del sistema nervoso periferico
                    e dei tessuti molli
                  </strong>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.icon}>
                <AccessibilityIcon />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography variant="h6" color="textSecondary">
                  <strong>Terapie infiltrative ecoguidate</strong>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.icon}>
                <WavesIcon />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography variant="h6" color="textSecondary">
                  <strong>Terapia con onde d’urto focali, ecoguidate</strong>
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Paragrpah
