import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Paragrpah from "../components/paragraph"
import WhereSection from "../components/whereSection"

const IndexPage = () => (
  <Layout>
    <SEO title="Dott. Egisto Federici" />
    <Hero />
    <Paragrpah title="Esperienza lavorativa in strutture ospedaliere e private" />
    <WhereSection title="Dove" />
  </Layout>
)

export default IndexPage
