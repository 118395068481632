import React from "react"
import { Typography, Grid, Card, CardContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import RoomIcon from "@material-ui/icons/Room"

const useStyle = makeStyles(theme => ({
  container: {
    padding: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  content: {
    marginBottom: "3rem",
    padding: "0 64px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 8px",
    },
  },
  card: {
    minWidth: 300,
    backgroundColor: "#0a4458",
    borderRadius: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px",
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}))

var areas = [
  {
    name: "Studio Privato",
    zone: "Chiavari",
    address: "Via Vinelli 9/4, 16043 Chiavari (GE)",
    phone: "0185 323529/320220",
    url: "/",
  },
  // {
  //   name: "Istituto fisioterapico Ti-Riabilita",
  //   zone: "Sarzana",
  //   address: "Via del Murello, 6, 19038 Sarzana (SP)",
  //   phone: " 0187 300577",
  //   url: "https://www.tiriabilita.com/team/dott-egisto-federici/",
  // },
  {
    name: "Istituto fisioterapico Fisiocare",
    zone: "Rapallo",
    address: "Via Lamarmora, 25 A - 16035 Rapallo (GE)",
    phone: " 0185 234055",
    url: "/",
  },
]

const WhereSection = ({ title, content }) => {
  const classes = useStyle()

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} id="zones">
        <Typography
          variant="h4"
          color="primary"
          gutterBottom
          className={classes.content}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.content}
        >
          Dopo 27 anni di attività ospedaliera, attualmente svolge la
          professione di libero professionista in diverse strutture della
          Liguria:
        </Typography>
        <Grid justify="space-evenly" container>
          {areas.map(a => {
            return (
              <Card raised className={classes.card}>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#1976d2",
                    }}
                  >
                    <RoomIcon style={{ color: "#15D48E" }} />
                    <Typography
                      className={classes.title}
                      color="textPrimary"
                      gutterBottom
                    >
                      {a.zone}
                    </Typography>
                  </div>
                  <Typography variant="h5" component="h2" color="textPrimary">
                    {a.url === "/" ? a.name : <a href={a.url}>{a.name}</a>}
                  </Typography>
                  <Typography className={classes.pos} color="textPrimary">
                    {a.address}
                  </Typography>
                  <Typography variant="body2" component="p" color="textPrimary">
                    <a
                      href={"tel:" + a.phone}
                      title={"Numero Telefono " + a.name}
                      style={{ color: "white" }}
                    >
                      {a.phone}
                    </a>
                  </Typography>
                </CardContent>
              </Card>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WhereSection
