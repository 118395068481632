import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import doctorImg from "../images/image.jpg"
import { Grid, Button } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: {
    //backgroundColor: rgba(255, 0, 0, 0.5),
    // backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    position: "relative",
    // height: "70vh",
    textAlign: "center",
    zIndex: 2,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(9),
  },
  imgContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  img: {
    zIndex: 10,
    borderRadius: "50%",
    // border: "2px white solid",
    width: "350px",
    height: "350px",
    [theme.breakpoints.down("sm")]: {
      width: "280px",
      height: "280px",
    },
  },
  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontWeight: "normal",
    },
  },
  goToZones: {
    backgroundColor: "#15D48E",
    color: "#fff",
    margin: "1.5em 0em",
    "&:hover": {
      color: "#fff",
    },
  },
}))

const Hero = () => {
  const classes = useStyles()

  return (
    <div className={classes.container + " heroBg"}>
      <Grid container alignItems="center" spacing={0} justify="center">
        <div className="box left top"></div>
        <div className="box right bottom"></div>
        <div className="box left bottom"></div>
        <div className="box right top"></div>
        <Grid item md={3} xs={12}>
          <img
            src={doctorImg}
            alt="Dottore Egisto Federici"
            className={classes.img}
          />
        </Grid>
        <Grid item lg={3} md={12}>
          <Typography
            variant="h2"
            gutterBottom
            component="h1"
            color="textPrimary"
            className={classes.title}
          >
            Dr. Egisto Federici
          </Typography>
          <Typography variant="h3" gutterBottom color="textPrimary">
            Medico Chirurgo
          </Typography>
          <Typography variant="h4" color="textPrimary">
            Specialista in radiodiagnostica dal 1990
          </Typography>
          <Button
            className={classes.goToZones}
            variant="contained"
            color="primary"
            href="#zones"
          >
            <strong>Dove Visito</strong>
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default Hero
